import React from 'react';
import { graphql } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import InnerPagesBanner from '../components/inner-pages-banner/inner-pages-banner';
import ClientsContainer from '../components/clients-container/clients-container';

export default ( props ) => {

  const innerBannerData = {
    backgroundImg: 
      <BackgroundImage 
        Tag="section"
        className="inner-pages__background"
        fluid={props.data.background_placeholder.childImageSharp.fluid}
      ></BackgroundImage>,
    mainTitle: 'Our Clients',
    mainText: ''
  };

  /**
   * Client data block
   */
  const clientsData = {

    home: false,

    hidden: [
      {
        mainImg: <Img alt="rsmd" fluid={props.data.rsmd.childImageSharp.fluid}/>,
        logo: <Img alt="rsmd logo" fluid={props.data.rsmd_logo.childImageSharp.fluid}/>,
        link: 'rsmd',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.rsmd.london/'
      },
      {
        mainImg:  <Img alt="cps" fluid={props.data.canon_cps.childImageSharp.fluid}/>,
        logo: <Img alt="cps logo" fluid={props.data.canon_logo.childImageSharp.fluid}/>,
        link: 'cps',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.canon-europe.com/pro/canon-professional-services/'
      }
    ],
    view: [
      {
        mainImg: <Img alt="pro" fluid={props.data.canon_pro.childImageSharp.fluid}/>,
        logo: <Img alt="pro logo" fluid={props.data.canon_logo.childImageSharp.fluid}/>,
        link: 'pro',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.canon-europe.com/pro'
      },
      {
        mainImg:  <Img alt="usi" fluid={props.data.usimoney.childImageSharp.fluid}/>,
        logo: <Img alt="usi logo" fluid={props.data.usi_logo.childImageSharp.fluid}/>,
        link: 'usimoney',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
        companyLink: 'https://www.usimoney.com/'
      },
    ]
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={[`digital`, `agency`, `technology`, `Radium`, `Clients`]} 
      />
      <InnerPagesBanner {...innerBannerData}/>
      <ClientsContainer {...clientsData}/>
    </Layout>
  )
};

export const clientQuery = graphql`
  query {

    background_placeholder: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "placeholder1300x800.png" }) {
      ...fluidNormal
    }

    canon_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "canon-logo.png" }) {
      ...fluidNormal
    }

    rsmd_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "rsmd-logo.png" }) {
      ...fluidNormal
    }

    usi_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "usi-logo.png" }) {
      ...fluidNormal
    }

    canon_pro: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "pro.png" }) {
      ...fluidNormal
    }

    canon_cps: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "cps.png" }) {
      ...fluidNormal
    }

    rsmd: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "rsmd.png" }) {
      ...fluidNormal
    }

    usimoney: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "usi.png" }) {
      ...fluidNormal
    }
  }
`;