import React, { Component } from 'react';
import ClientTemplate from '../clients-template/clients-template';

import Styles from './accordion.module.scss';

class Accordion extends Component  {
  constructor(props) {
    super(props);

    this.state = { 
      height: 0,
      isOpen: false, 
    };

    this.accordionHandler = this.accordionHandler.bind( this );

    this.accordion = React.createRef();
  }

  /**
   * Accordion Handler
   * Tracks the current state of the accordion
   * @async this.setState()
   * @param this.accordion.current.clientHeight inner element height
   * @constant 40 margin value
   */
  accordionHandler() {
    const height = this.accordion.current.clientHeight + 40;
    this.setState((state) => ({ isOpen: !state.isOpen, height }));
  }

  render () {

    return (
      <div>
        <div className={Styles.outerAccordion}
             style={
              this.state.isOpen ? 
                { maxHeight: `${this.state.height}px` } : { maxHeight: 0 }
            }
        >
          <div className={Styles.innerAccordion} ref={this.accordion}>
            {Object.entries(this.props).map( ( [key, value] ) => 
              <ClientTemplate key={key} {...value}/>
            )}
          </div>
        </div>
        
        <div className={Styles.linkButton}>
          <button 
            className="button__content button--pulse"
            onClick={this.accordionHandler}
            aria-label="Load button"
          >
            Load {this.state.isOpen ? 'Less' : 'More'}
          </button>
        </div>
      </div>
    )
  }
};

export default Accordion;
