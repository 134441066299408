import React from 'react';
import { Link } from 'gatsby';
import { FaArrowRight } from 'react-icons/fa';

import Styles from './clients-template.module.scss';

export default ({ mainImg, link, logo, subTitle, text, companyLink }) => (
  
  <div className={Styles.clientContent}>
    <Link
      to={`/clients/${link}`}
    >
      <div className={Styles.ImgOuterContainer}>
          <div className={Styles.ImgInnerContainer}>
              {mainImg}
          </div>
      </div>
    </Link>
    <div className={Styles.textContainer}>
      <div className={Styles.logoImg}>
        {logo}
      </div>
      <h4 className={Styles.subTitle}>{subTitle}</h4>
      <p className={Styles.text}>{text}</p>
      <a 
        className="button__content button--svg-animation"
        href={companyLink}
        target="_blank" 
        rel="noopener noreferrer"
      >
        Visit Site
        <FaArrowRight />
      </a>
    </div>
  </div>
);
